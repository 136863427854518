import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Form } from 'components/pages/book-demo/form';

import { Header } from '../components/blocks/header';
import { buildPageMetadata } from '../utils/data-builders';

const BookDemo = ({ data: { wpPage } }) => {
  const initialValues = {};

  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);

    const firstName = url.searchParams.get('firstName');
    const lastName = url.searchParams.get('lastName');
    const companyName = url.searchParams.get('companyName');
    const email = url.searchParams.get('email');

    initialValues.firstName = firstName;
    initialValues.lastName = lastName;
    initialValues.companyName = companyName;
    initialValues.email = email;
  }

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Form
        title={wpPage.title}
        description={wpPage.acf.description}
        initialValues={initialValues}
      />
    </Layout>
  );
};

export default BookDemo;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      title
      acf {
        description
      }
    }
  }
`;
