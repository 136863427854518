import * as React from 'react';

import { BookDemo } from 'components/blocks/book-demo';
import { Container } from 'components/shared/container';

import { Wrapper, Box, Header, StyledTitle, Description } from './book-demo-form.styles';

export const Form = ({ title, initialValues, description }) => (
  <Wrapper>
    <Container>
      <Header>
        <StyledTitle size="lg">{title}</StyledTitle>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Header>
      <Box>
        <BookDemo initialValues={initialValues} />
      </Box>
    </Container>
  </Wrapper>
);
