import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Box = styled.div`
  max-width: 620px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 30px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;

  ${media.sm} {
    max-width: 480px;
  }

  ${media.xs} {
    padding-right: 25px;
    padding-bottom: 30px;
    padding-left: 25px;
  }
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  margin-bottom: 15px;
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 50px;

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding-top: 120px;
  padding-bottom: 90px;

  ${media.sm} {
    padding-top: 100px;
    padding-bottom: 50px;
  }
`;

export const Description = styled.p`
  line-height: 1.75;
  max-width: 620px;
  margin: 0 auto;

  ${media.sm} {
    font-size: ${fontSize.sm};
  }
`;
