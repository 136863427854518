import dateFormat from 'dateformat';

export function buildAuthor(authorData) {
  return {
    fullName: authorData?.title,
    jobTitle: authorData?.acf?.jobTitle,
    avatar: authorData?.acf?.avatar,
  };
}

function calculateReadTime(content) {
  const wordsPerMinute = 200; // Average case.
  const textLength = content.split(' ').length; // Split by words
  return Math.ceil(textLength / wordsPerMinute);
}

export function buildPost(wordpressPost) {
  const { acf, title, date, content, id, uri } = wordpressPost.node || wordpressPost;

  return {
    id,
    author: buildAuthor(acf.author),
    readTime: calculateReadTime(content),
    image: acf.image,
    path: uri,
    date,
    title,
    content,
  };
}

export function buildPostByWordpressApiObject(object) {
  const { id, acf, title, content, date, slug, date_gmt } = object;

  return {
    id,
    author: buildAuthor(acf.author),
    readTime: calculateReadTime(content.rendered),
    image: acf.image,
    date: dateFormat(new Date(date), 'mmmm dd, yyyy'),
    title: title.rendered,
    content: content.rendered,
    path: `${dateFormat(new Date(date_gmt), '/yyyy/mm/dd')}/${slug}`,
  };
}

export function buildPageMetadata({ seo }) {
  const defaultImage = 'images/WeGift-Incentive-Marketing.png';
  // TODO: refactoring - get the site url from the GraphQL - wp => generalSettings => url
  const siteUrl = 'https://wegift.io';
  const ogImageUrl = seo.opengraphImage?.localFile?.childImageSharp
    ? siteUrl + seo.opengraphImage.localFile.childImageSharp.fixed.src
    : defaultImage;

  return {
    title: seo.title,
    description: seo.metaDesc,
    ogImage: ogImageUrl,
  };
}
